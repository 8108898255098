import Validation from 'bluegg-validation';
import MicroModal from 'micromodal';  // es6 module
import Glide from '@glidejs/glide';
import AOS from 'aos';
import throttle from "lodash.throttle"
import smoothscroll from 'smoothscroll-polyfill';
import LazyLoad from "vanilla-lazyload";

document.documentElement.classList.remove('no-js');

/** 
* title Modal
* description Accessible Modals
**/
function modalActivate() 
{
    MicroModal.init({
        onShow: modal => modal.classList.remove('hidden'),
        onClose: modal => modalClose(modal, event),
        openTrigger: 'data-modal-open',
        closeTrigger: 'data-modal-close',
        disableScroll: true,
    });
};

function modalClose(modal, event) {
    event.preventDefault();    
    modal.classList.add('hidden');
}

modalActivate();

/** title Modal Fix for Sprig refresh 
**/
function refineModalActivate() 
{
    MicroModal.init({
        onShow: modal => refineModalOpen(modal, event),
        onClose: modal => refineModalClose(modal, event),
        openTrigger: 'data-refine-modal-open',
        closeTrigger: 'data-refine-modal-close',
        disableScroll: true,
    });
};

function refineModalOpen(modal, event) {
    event.preventDefault();    
    modal.classList.remove('hidden');
    const refineModalContent = modal.querySelector('[data-modal-content]');

    setTimeout(function()
    { 
      delete modal.dataset.modal; 
      refineModalContent.id = 'modal-content-open'
    }, 3000);
}

function refineModalClose(modal, event) {
    event.preventDefault();    
    modal.classList.add('hidden');
    const refineModalContent = modal.querySelector('[data-modal-content]');
    
    modal.setAttribute('data-modal', 'refine'); 
    refineModalContent.id = 'modal-content'
        
}

refineModalActivate();

document.addEventListener('address-load', function () {
    modalActivate();
});

/** 
* title Animation Settings
**/
AOS.init({
    offset: 200,
    duration: 500,
    once: true,
});

/** 
* title User Form Valudation
* description Accessible Modals
**/
const fields = document.querySelector('.form-field');
if (fields) {
    var userForms = new Validation({
    	form: '[data-user-form]',
    	errorClass: 'field-error',
      fieldContainer: '.form-field',
    });
}

/** 
* title Lazy Load
* description Lazy Loading Images
**/

window.lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    cancel_on_exit: false
});

if (typeof htmx !== 'undefined') {
  htmx.on('htmx:afterSwap', function(event) {
      window.lazyLoadInstance.update();
  });
}

/**
* title: Notification Banner
**/
import "./modules/notification-banner"

/**
* title: Cookies
**/
import initCookie from './modules/cookie-notice.js';

if (document.getElementById('cookie-notice')) {
    initCookie();    
}

/**
* title: Mobile Menu
**/
import { openNavigation, closeNavigation } from "./molecules/mobile-menu"

/**
* title: Desktop Menu
**/
import "./molecules/menu"

/** 
* title Accordions
**/
import "./molecules/accordion"

/** Header Scroll **/

import headerScroller from './molecules/header-scroll';
window.addEventListener('search-loaded', throttle(headerScroller, 50), false);


/**
* title Drop-downs
* description Create manual drop-downs if a select isn't viable
*/
function customDropdown() {
	// TODO: Don't think we need to store a query selector call and test if its in the doc
    const dropdownParent = document.querySelectorAll('[data-dropdown-parent]');    
    if (dropdownParent.length) {
				
				// TODO: Consider refactor to for of or foreach
        for (let i = 0; i < dropdownParent.length; i++) {
            const dropdownChild = dropdownParent[i].querySelector('[data-dropdown-button]');
            const dropdown = dropdownParent[i].querySelector('[data-dropdown]');
                    
            dropdownChild.addEventListener('click', function(e) {
                            
                const siblings = getSiblings(dropdown);
                                                                        
                for (let i = 0; i < siblings.length; i++) {                    
                    siblings[i].removeAttribute('data-dropdown-open');
                    var drop = siblings[i].querySelector('[data-dropdown]');
                                    
                    if (drop) {
                        drop.classList.add('hidden');
                    }                    
                } 
                                                                                
                dropdown.classList.toggle('hidden');
                dropdown.parentNode.toggleAttribute('data-dropdown-open'); 
            });
        }
    }
};
customDropdown();  

function getSiblings(elem) {
    let siblings = [];
    let sibling = elem.parentNode.parentNode.firstChild;
        
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem.parentNode) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling    
    }
    return siblings;
};

/**
 * cards Glide JS
 * description Create Carousel for Image Gallery
 */
const cardSliders = document.querySelectorAll('.glide-cards');
cardSliders.forEach(cardSlider => {
    var itemGlider = new Glide(cardSlider, {
        perView: 1,
    });

    itemGlider.mount();
});

/**
 * items Glide JS
 * description Create Carousel for scrollable items
 */
const glideItems = document.querySelectorAll('.glide-items');
glideItems.forEach(glideItem => {
    var itemGlider = new Glide(glideItem, {
        type: 'carousel',
        autoplay: 3500,
        perView: 5,
        focusAt: 'center',
        breakpoints: {
            1600: {
                perView: 3.8,
            },
            1080: {
                perView: 2.8,
            },
            760: {
                perView: 2,
            },
            500: {
                perView: 1.8,
            }
        }
    });

    itemGlider.mount();
});

/**
* title Glide JS
* description Create Carousel for Image Gallery
*/
const sliders = document.querySelectorAll('.glide');

if (sliders.length) {
		
	for (var i = 0; i < sliders.length; i++) {
      
    var glide = new Glide(sliders[i], {
      type: 'carousel',
      perView: 2.2,
      gap: 50,
      breakpoints: {
        768: {
          perView: 1.6,
        },
        500: {
          perView: 1.3,
        }
      }
    })
    glide.mount();    
  }
}

/**
* title Glide JS
* description Create Carousel for Post Gallery
*/
// TODO: Only used on posts templates
const postSlider = document.querySelector('.post-gallery');

if (postSlider) {
  const postSliders = document.querySelectorAll('.post-gallery');
	
	for (var i = 0; i < postSliders.length; i++) {
      
    var glide = new Glide(postSliders[i], {
      type: 'carousel',
      perView: 1,
    })
    glide.mount();    
  }
}

/** 
* title Timeline Slider
**/
// TODO: Is this required on every page?
// TODO: Only used in 1 component on 1 page (but is in rich content templste matrix) - find better way of only including there
import { timelineSlider, timelineScroll } from "./molecules/timeline"
timelineScroll();
window.addEventListener('load', timelineSlider, false);
window.addEventListener('scroll', throttle(timelineSlider, 50), false);


/** 
* title Sprig Refresh Trigger
* desc  Rerun JS once Sprig has re-rendered the components
**/

// TODO: Is this required on every page?
// TODO: Check with paul - should be only on basket but maybe prodcts
import DeliveryMessageCountdown from "./modules/delivery-message-countdown"
DeliveryMessageCountdown()

// TODO: Is this required on every page?
// TODO: Check with paul - should be only on basket but maybe prodcts
import DispatchCountdown from "./modules/dispatch-countdown"
DispatchCountdown()

// TODO: Is this required on every page?
// TODO: Only on checkout - move to checkout JS
// import contactName from "./molecules/contact-name"
// contactName()

document.addEventListener('comp-reloaded', function () {
    customDropdown();
    window.lazyLoadInstance.update();
    modalActivate();
    refineModalActivate();
});

/*** Show/Hide ***/
import showHide from "./molecules/show-hide"
showHide();

/**
* title Smooth Scroll
* desc  Add Smooth Scroll to Anchor Buttons
**/

const buttonScroll = document.querySelectorAll('[data-button-scroll]');
for (let i = 0; i < buttonScroll.length; i++) {
  
  buttonScroll[i].addEventListener("click", function(e){
    e.preventDefault();
    const href = this.getAttribute("href");
    const offsetTop = document.querySelector(href).offsetTop - 80;
    
    scroll({
        top: offsetTop,
        behavior: "smooth"
    });
    
    smoothscroll.polyfill();
        
  });
    
}

document.addEventListener('chatlio.ready', function (e) {
  const liveChatBtn = document.querySelectorAll('.toggle-chat');
      
  for (const el of liveChatBtn)
	{
    el.setAttribute('href', 'javascript:void(0)');
    el.setAttribute('title', 'Open Live Chat');
    
    el.addEventListener("click", function(e) {
      _chatlio.show({expanded: true});
    }); 
  }
}, false);
  
  
/**
* title Filter Drop-downs
* description Create manual drop-downs for filters
*/
function filterDropdowns() {
    const filterParent = document.querySelector('[data-filter-parent]');    
    if (document.body.contains(filterParent)) {
        const filterParent = document.querySelectorAll('[data-filter-parent]');
        const filterChildren = document.querySelectorAll('[data-filter]');
        for (let i = 0; i < filterParent.length; i++) {
            const filterChild = filterParent[i].querySelector('[data-filter-button]');
            const filter = filterParent[i].querySelector('[data-filter]');
                    
            filterChild.addEventListener('click', function(e) {                                                                
                filter.classList.toggle('hidden');
                filter.parentNode.toggleAttribute('data-filter-open'); 
            });
        }
    }
};
filterDropdowns();

const anchorTags = document.querySelectorAll('a');
anchorTags.forEach(anchor => {
    anchor.addEventListener('click', (event) => {
        if (anchor.getAttribute('href').includes('#')) {
            event.preventDefault();
            const elementId = anchor.getAttribute('href').slice(1);
            const element = document.getElementById(elementId);
            const elementPosition  = element.getBoundingClientRect().top + window.scrollY;
            if (elementPosition) {
                window.scrollTo({
                    top: elementPosition - 125,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    });
});

import axios from "axios/dist/axios"
function updateCartItemCount() {
    axios.get('/actions/commerceadjustments/cart/get-cart-item-count')
        .then(function (response) {
            const itemCount = response.data.itemCount;
            document.getElementById('cart-total').textContent = `${itemCount}`;
        })
        .catch(function (error) {
            console.error('Error fetching cart item count:', error);
        });
}

document.addEventListener('DOMContentLoaded', function () {
    updateCartItemCount();
});